<template>
  <fragment v-if="!isLoading">
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th width="5%">
              <!--<v-checkbox
                v-model="item.check"
                hide-details
                class="my-0"
              ></v-checkbox>-->
            </th>
            <th>
              <span v-if="item.code">
                {{ item.code }}
              </span>
            </th>
            <th width="50%"></th>
            <th>{{ item.data_markups.precioSuplementPayOnline | currency(item.data_service.items.currencySymbolFacturar ? item.data_service.items.currencySymbolFacturar : '$') }}</th>
            <th width="5%">
              <v-menu
                bottom
                left
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <!--<v-list-item link>
                    <v-list-item-title>
                      <v-icon
                        size="20"
                        class="me-2"
                      >
                        {{ icons.mdiSendOutline }}
                      </v-icon>
                      <span>{{ $t('btn.share') }}</span>
                    </v-list-item-title>
                  </v-list-item>-->
                  <v-list-item link>
                    <!--@click="editServive"-->
                    <v-list-item-title>
                      <v-icon
                        size="20"
                        class="me-2"
                      >
                        {{ icons.mdiSquareEditOutline }}
                      </v-icon>
                      <span>{{ $t('btn.edit') }}</span>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item link>
                    <!--@click="deleteItem"-->
                    <v-list-item-title>
                      <v-icon
                        size="20"
                        class="me-2"
                      >
                        {{ icons.mdiDeleteOutline }}
                      </v-icon>
                      <span>{{ $t('btn.delete') }}</span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              colspan="2"
              class="pb-8"
            >
              <v-img
                :src="
                  nameB2B === 'ttttt'
                    ? rutaS3 + remplaceUrl(image)
                    : nameB2B === 'wwwww'
                      ? rutaS3 + image
                      : rutaPublic + image
                "
                contain
                width="250"
                class="mb-5"
                style="text-align: right !important;"
              >
                <template>
                  <v-chip
                    class="mr-2 mt-2"
                    color="primary"
                  >
                    <span v-if="checkOnRequest">On Request</span>
                    <span v-else>Free Sale</span>
                  </v-chip>
                </template>
              </v-img>

              <b :style="`color: ${$vuetify.theme.themes.light.primary}`">{{ item.data_service.items.car.name }}</b>
              <br />
              <br />
              <span :style="`color: ${$vuetify.theme.themes.light.primary}`"> {{ `${$t('menu.operador')}:` }} </span>
              {{ contrate.rentadora.name }}
              <br />
              <span :style="`color: ${$vuetify.theme.themes.light.primary}`"> {{ `${$t('lbl.proveedor')}:` }} </span>
              {{ contrate.proveedor.name_comercial }}
            </td>
            <td
              style="vertical-align: top;border: 0;"
              colspan="3"
            >
              <br />
              <b>
                <span class="text-uppercase">
                  {{ $t('lbl.rentToCars') }}
                  ({{ `${item.data_service.items.diasReservar} ${$t('lbl.day')}` }})
                </span>
                <span v-if="item.data_service.items.category_id">
                  {{ `, ${item.data_service.items.category_id.name} ${$t('lbl.withSecureInclude')}` }}
                </span>
              </b>
              <br />
              <br />
              <v-row no-gutters>
                <v-col
                  cols="12"
                  md="3"
                >
                  <b>{{ $t('lbl.recogida') }}:</b>
                </v-col>
                <v-col
                  cols="12"
                  md="9"
                >
                  <v-icon
                    color="primary"
                    class="mr-2"
                    small
                  >
                    mdi-calendar-range
                  </v-icon>
                  {{ $moment(item.data_service.dateRecogida).format('DD MMM, Y') }},
                  {{ $moment(item.data_service.dateRecogida).format('HH:mm') }}
                  <v-icon
                    v-if="item.data_service.recogida"
                    color="primary"
                    class="mr-2"
                    small
                  >
                    {{ item.data_service.recogida.icon }}
                  </v-icon>
                  {{ item.data_service.recogida.name }}
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <b>{{ $t('lbl.entrega') }}:</b>
                </v-col>
                <v-col
                  cols="12"
                  md="9"
                >
                  <v-icon
                    color="primary"
                    class="mr-2"
                    small
                  >
                    mdi-calendar-range
                  </v-icon>
                  {{ $moment(item.data_service.dateEntrega).format('DD MMM, Y') }},
                  {{ $moment(item.data_service.dateEntrega).format('HH:mm') }}
                  <v-icon
                    v-if="item.data_service.entrega"
                    color="primary"
                    class="mr-2"
                    small
                  >
                    {{ item.data_service.entrega.icon }}
                  </v-icon>
                  {{ item.data_service.entrega.name }}
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                  class="mt-5 mb-5"
                >
                  <b>{{ $t('lbl.drivers') }}:</b>
                </v-col>
                <v-col
                  cols="12"
                  md="9"
                  class="mt-5 mb-5"
                >
                  {{ item.data_client.nameConductorPrincipal ? item.data_client.nameConductorPrincipal : '' }}
                  {{ item.data_client.apellidosConductorPrincipal ? item.data_client.apellidosConductorPrincipal : '' }}
                  <b>({{ $t('lbl.principate') }})</b><br />
                  <span v-if="item.data_client.conductorAditional">
                    {{ item.data_client.nameConductorAditional ? item.data_client.nameConductorAditional : '' }}
                    {{
                      item.data_client.apellidosConductorAditional ? item.data_client.apellidosConductorAditional : ''
                    }}
                    <b>({{ $t('lbl.adicional') }})</b>
                  </span>
                </v-col>

                <v-col
                  cols="12"
                  md="3"
                >
                  <b>{{ $t('lbl.paymentOther') }}:</b><br />
                  <span class="text-caption">({{ $t('lbl.payToRent') }})</span>
                </v-col>
                <v-col
                  cols="12"
                  md="9"
                >
                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      md="9"
                    >
                      {{ $t('lbl.dayExtra') }} ({{ item.data_service.items.currencySymbolFacturar ? item.data_service.items.currencySymbolFacturar : '$' }} / {{ $t('lbl.dia') }})
                    </v-col>
                    <v-col
                      cols="12"
                      md="3"
                      class="text-right"
                    >
                      <span class="mx-4">{{ item.data_markups.tarifaDayExtra | currency(item.data_service.items.currencySymbolFacturar ? item.data_service.items.currencySymbolFacturar : '$') }}</span>
                    </v-col>

                    <v-col
                      cols="12"
                      md="9"
                    >
                      {{ $t('lbl.tarifaRetorno') }} ({{ item.data_service.items.currencySymbolFacturar ? item.data_service.items.currencySymbolFacturar : '$' }} / Km)
                    </v-col>
                    <v-col
                      cols="12"
                      md="3"
                      class="text-right"
                    >
                      <span class="mx-4">{{ item.data_markups.precioTarifaRetorno | currency(item.data_service.items.currencySymbolFacturar ? item.data_service.items.currencySymbolFacturar : '$') }}</span>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                  class="text-center"
                >
                  <span v-if="item.code && checkInfoPendient">
                    <v-chip
                      color="red"
                      text-color="white"
                      @click="editServive"
                    >
                      {{ $t('lbl.completeInfo') }}
                    </v-chip>
                  </span>
                </v-col>
              </v-row>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <!-- MOSTRAR DELETE -->
    <v-dialog
      v-model="showModalDelete"
      scrollable
      max-width="400px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.delete')} ${$t('lbl.service')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>{{ $t('msg.deleteServicio', { item: '' }) }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="showModalDelete = !showModalDelete"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="confirmDeleteService"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiSendOutline,
  mdiCurrencyUsd,
  mdiPrinter,
  mdiSquareEditOutline,
  mdiClipboardCheckOutline,
  mdiAccountCashOutline,
  mdiDotsVertical,
  mdiHeartPlusOutline,
  mdiDeleteOutline,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    item: {
      type: Object,
    },
  },
  data() {
    return {
      isLoading: true,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      nameB2B: process.env.VUE_APP_PROJ_NAME,
      rutaS3: process.env.VUE_APP_API_URL_S3_CAR,
      showModalDelete: false,

      icons: {
        mdiSendOutline,
        mdiCurrencyUsd,
        mdiPrinter,
        mdiSquareEditOutline,
        mdiClipboardCheckOutline,
        mdiAccountCashOutline,
        mdiDotsVertical,
        mdiHeartPlusOutline,
        mdiDeleteOutline,
      },
      textRequiered: [v => !!v || 'Campo requerido'],
      image: null,
      contrate: null,
    }
  },
  computed: {
    ...mapState({
      clientCarShop: state => state.app.clientCarShop,
      carItems: state => state.app.carItems,
      loadingBtn: state => state.app.loadingBtn,
    }),
    checkOnRequest() {
      let onRequest = false
      if (
        this.item.data_service.items.cuposDisponibles === 0
        || this.item.data_service.items.cuposDisponibles === 'null'
        || this.item.data_service.items.cuposDisponibles === null
      ) {
        onRequest = true
      }

      return onRequest
    },

    checkInfoPendient() {
      let info = false
      if (
        !this.item.data_client.nameConductorPrincipal
        || !this.item.data_client.apellidosConductorPrincipal
        || !this.item.data_client.birthdayConductorPrincipal
        || !this.item.data_client.noDocumentConductorPrincipal
      ) {
        info = true
      }

      if (this.item.data_client.conductorAditional) {
        if (
          !this.item.data_client.nameConductorAditional
          || !this.item.data_client.apellidosConductorAditional
          || !this.item.data_client.birthdayConductorAditional
          || !this.item.data_client.noDocumentConductorAditional
        ) {
          info = true
        }
      }

      return info
    },
  },

  created() {
    this.getContrate()
    if (this.item.data_service.items.car.galery_random === null) {
      this.image = 'config/car_placeholder.png'
    } else {
      this.image = this.item.data_service.items.car.galery_random
    }
  },
  methods: {
    ...mapMutations(['updateItemToCar', 'emptyClientCarShop', 'updateLoadingBtn', 'deleteItemToCar']),
    getContrate() {
      this.axios
        .get(`contrate_cars/${this.item.data_service.items.contrate_id}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.contrate = res.data.data.data
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    deleteItem() {
      this.showModalDelete = true
    },
    confirmDeleteService() {
      this.showModalDelete = false

      const cars = localStorage.getItem('car-shop') ? JSON.parse(localStorage.getItem('car-shop')) : []
      cars.splice(this.pos, 1)
      if (cars.length === 0) {
        localStorage.removeItem('car-shop')
        this.$router.push({ name: 'cotizador' })
      }

      localStorage.setItem('car-shop', JSON.stringify(cars))
      this.deleteItemToCar(this.pos)
    },
    printInvoice() {
      window.print()
    },
    editServive() {
      const json = {
        pos: this.pos,
        item: this.item,
      }

      localStorage.setItem('car-shop-item', JSON.stringify(json))
      localStorage.setItem('route-redirect', 'cotizations-update')

      this.$router.push({ name: 'car-shop-item-update' })
    },
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/invoice.scss';

tbody {
  tr:hover {
    background-color: transparent !important;
  }
}

@media print {
  .v-application {
    background: none !important;
  }

  .app-navigation-menu,
  .v-app-bar,
  .v-footer,
  .product-buy-now,
  .app-invoice-preview.row .col-12:last-of-type {
    display: none !important;
  }

  .app-invoice-preview.row .col-12:first-child {
    max-width: 100% !important;
    flex: 1 !important;
  }

  .v-main,
  .app-content-container {
    padding: 0px !important;
  }

  .v-card {
    box-shadow: none !important;
  }

  .app-invoice-preview {
    .invoice-header,
    .payment-details,
    .invoice-total {
      &.d-flex {
        flex-direction: row !important;
      }
    }
  }
}
</style>
