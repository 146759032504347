<!-- eslint-disable no-unused-vars -->
<!-- eslint-disable vue/no-template-shadow -->
<template>
  <fragment>
    <v-row
      v-if="!isLoading"
      class="match-height"
    >
      <v-col cols="12">
        <v-card-text class="no-printme">
          <!--<v-row>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="error"
                class="mb-4 me-3"
                @click="$router.push({ name: 'reservations' })"
              >
                <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
                <span>{{ $t('btn.back') }}</span>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="primary"
                class="mb-4 me-3"
                @click="save()"
              >
                <span>{{ $t('btn.update') }}</span>
                <v-icon>{{ icons.mdiUpdate }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>-->

          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <b>{{ $t('lbl.cotization') }}</b>: {{ item.code }}<br />
              <fragment v-if="item.cliente">
                <b>{{ $t('lbl.client') }}</b>: {{ `${item.cliente.name} ${item.cliente.apellidos ? item.cliente.apellidos : ''}` }}<br />
              </fragment>
              <b>{{ $t('lbl.agency') }}</b>: {{ item.afiliado.nivel === 0 ? 'Propietario' : item.afiliado.company }}
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <b>{{ $t('register.moneda') }}</b>: {{ currencyCodeItem }}
              <!--{{ item.ctas_bancarias.moneda }}-->
              <br />
              <b>{{ $t('lbl.homeService') }}</b>: {{ $moment(item.date_servicio[0]).format('DD MMM Y') }}<br /> <!--, H:m-->
              <fragment v-if="item.date_servicio_end.length > 0">
                <b>{{ $t('lbl.endService') }}</b>: {{ $moment(item.date_servicio_end[0]).format('DD MMM Y') }}<br /> <!--, H:m-->
              </fragment>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="9"
            >
              <v-card>
                <v-card-text>
                  <div
                    v-for="(carItem, indI) in carItems"
                    :key="indI"
                  >
                    <ItemCar
                      v-if="carItem.entity === 'cars'"
                      :pos="indI"
                      :item="carItem"
                    />
                    <ItemHotel
                      v-else-if="carItem.entity === 'hotels'"
                      :pos="indI"
                      :item="carItem"
                    />
                  </div>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col
              cols="12"
              md="3"
            >
              <v-row no-gutters>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-btn
                    color="error"
                    class="mb-3"
                    block
                    @click="$router.push({ name: 'reservations' })"
                  >
                    <v-icon
                      class="me-2"
                      left
                    >
                      {{ icons.mdiArrowLeft }}
                    </v-icon>
                    <span>{{ $t('btn.back') }}</span>
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-btn
                    color="primary"
                    class="mb-3"
                    block
                    @click="sendPdf"
                  >
                    <v-icon
                      class="me-2"
                      left
                    >
                      {{ icons.mdiSendOutline }}
                    </v-icon>
                    <span>{{ $t('lbl.sendCotization') }}</span>
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-btn
                    class="mb-3"
                    color="secondary"
                    block
                    outlined
                    :loading="loadingDescargar"
                    @click="downloadPdf"
                  >
                    {{ $t('lbl.downloadCotization') }}
                  </v-btn>
                </v-col>
                <!--<v-col
                  cols="12"
                  md="12"
                >
                  <v-btn
                    class="mb-3"
                    color="secondary"
                    block
                    outlined
                    :loading="loadingImprimir"
                    @click="printPdf"
                  >
                    {{ $t('lbl.printCotization') }}
                  </v-btn>
                </v-col>-->
              </v-row>
              <v-row no-gutters>
                <v-col
                  cols="12"
                  md="12"
                >
                  <!--PRICE TOTAL-->
                  <div class="d-flex justify-space-between align-center">
                    <h3 class="my-2">
                      {{ $t('lbl.totalPay') }}
                    </h3>
                    <h3>
                      <h4>
                        {{ priceAllPay | currency(currencySymbolItem) }}
                      </h4>
                    </h3>
                  </div>
                  <!--PRICE ONLINE-->
                  <div class="d-flex justify-space-between align-center">
                    <span
                      class="pb-0 mb-0 pt-0 mt-0"
                      style="font-size: 12px"
                    > {{ $t('lbl.payOnline') }} </span>
                    <span
                      class="pb-0 mb-0 pt-0 mt-0"
                      style="font-size: 12px"
                    >
                      {{ priceOnlinePay | currency(currencySymbolItem) }}
                    </span>
                  </div>
                  <!--PRICE DESTINO-->
                  <div class="d-flex justify-space-between align-center">
                    <span
                      class="pb-0 mb-0 pt-0 mt-0"
                      style="font-size: 12px"
                    >
                      {{ $t('lbl.payDestino') }}
                    </span>
                    <span
                      class="pb-0 mb-0 pt-0 mt-0"
                      style="font-size: 12px"
                    >
                      {{ priceDestinoPay | currency(currencySymbolItem) }}
                    </span>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>

        <div class="printme">
          <Pdf
            :config="config"
            :model="item"
            :email-principal="emailPrincipal"
            :cars-items="carItems"
            :price-all-cars="priceAllCars"
            :price-all-reserve="priceAllReserve"
            :currencies="currencies"
          />
        </div>
      </v-col>

      <SidebarDataClient
        v-if="isSidebarDataClientActive"
        v-model="isSidebarDataClientActive"
        :from="btnName"
        :ctas-bancarias="ctasBancarias"
        @confirmPrintCotization="confirmPrint"
        @confirmSend="confirmSend"
      />
      <SidebarSendCotization
        v-if="isSidebarSendCotizationActive"
        v-model="isSidebarSendCotizationActive"
        :name-company="nameCompany"
        :name-user="nameUser"
        :name-b2b="nameB2b"
        :email-user="emailUser"
        :user="user"
        @confirmSendCotization="confirmSendCotization"
        @showReturnDataClient="showReturnDataClient"
      />
    </v-row>

    <v-row
      v-if="isLoading"
      class="my-2"
    >
      <v-col
        cols="8"
      >
        <div class="align-center">
          <v-skeleton-loader
            class="mx-auto"
            type="table-tbody"
          ></v-skeleton-loader>
        </div>
      </v-col>
      <v-col
        cols="4"
      >
        <div class="align-center">
          <v-skeleton-loader
            v-for="(i, ind) in 4"
            :key="ind"
            class="mx-auto"
            type="table-tfoot"
          ></v-skeleton-loader>
          <v-skeleton-loader
            class="mx-auto"
            type="actions"
          ></v-skeleton-loader>
          <v-skeleton-loader
            class="mx-auto"
            type="actions"
          ></v-skeleton-loader>
        </div>
      </v-col>
    </v-row>
  </fragment>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
  mdiMapPlus,
  mdiMapMinus,
  mdiInformationOutline,
  mdiCar,
  mdiBed,
  mdiCarDoor,
  mdiAccountOutline,
  mdiBagSuitcaseOutline,
  mdiPrinter,
  mdiEmail,
  mdiSendOutline,
  mdiAccountCashOutline,
} from '@mdi/js'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
// eslint-disable-next-line import/order, import/no-unresolved
import SidebarSendCotization from '@/views/booking/cotizador/selected/utils/SidebarSendCotization.vue'
// eslint-disable-next-line import/no-unresolved
import SidebarDataClient from '@/views/booking/cotizador/selected/utils/SidebarDataClient.vue'
// eslint-disable-next-line import/no-unresolved
import Pdf from '@/views/utils/viewPdf/utils/Pdf.vue'
import ItemCar from './utils/ItemCar.vue'
import ItemHotel from './utils/ItemHotel.vue'

export default {
  setup() {
    const { menuIsVerticalNavMini } = useAppConfig()

    return {
      menuIsVerticalNavMini,
    }
  },
  components: {
    ItemCar,
    ItemHotel,
    SidebarSendCotization,
    SidebarDataClient,
    Pdf,
  },
  data() {
    return {
      isLoading: true,
      isSidebarDataPaysActive: false,
      itemsPay: [],
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      name_project: process.env.VUE_APP_PROJ_NAME,
      rutaS3: process.env.VUE_APP_API_URL_S3_CAR,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
        mdiMapPlus,
        mdiMapMinus,
        mdiInformationOutline,
        mdiCar,
        mdiBed,
        mdiCarDoor,
        mdiAccountOutline,
        mdiBagSuitcaseOutline,
        mdiPrinter,
        mdiEmail,
        mdiSendOutline,
        mdiAccountCashOutline,
      },
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      modelTimeConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
      editReserva: false,
      editStatus: false,
      status: [],
      statusList: [],
      slugState: null,
      idConfirmState: 0,
      item: {},
      loadingDescargar: false,
      loadingImprimir: false,
      loadingEnviar: false,
      nameB2b: null,
      nameCompany: '',
      nameUser: '',
      emailUser: '',
      btnName: '',
      ctasBancarias: [],
      isSidebarDataClientActive: false,
      isSidebarSendCotizationActive: false,
      config: {
        logo: null,
        name: '',
      },
      priceAllCars: 0,
      priceAllReserve: 0,
      emailPrincipal: null,
      currencies: [],
    }
  },
  computed: {
    ...mapState({
      onlyShow: state => state.app.onlyShow,
      clientCarShop: state => state.app.clientCarShop,

      // carItems: state => state.app.carItems,
    }),
    priceAllPay() {
      const result = this.priceOnlinePay + this.priceDestinoPay

      return result
    },
    priceOnlinePay() {
      let result = 0
      this.carItems.forEach(carItem => {
        if (carItem.entity === 'cars') {
          result += carItem.data_markups.precioSuplementPayOnline
        } else if (carItem.entity === 'hotels') {
          result += carItem.data_service.items.rooms[0].priceWithDescuento
        }
      })

      return result
    },
    priceDestinoPay() {
      let result = 0
      this.carItems.forEach(carItem => {
        // if (carItem.check) {
        result += carItem.data_markups.precioSuplementPayRentator

        // }
      })

      return result
    },

    currencyCodeItem() {
      let result = 'EUR'
      if (this.carItems[0].data_markups.currencyCodeFacturar) {
        result = this.carItems[0].data_markups.currencyCodeFacturar
      }

      return result
    },

    currencySymbolItem() {
      let result = '$'
      if (this.carItems[0].data_markups.currencySymbolFacturar) {
        result = this.carItems[0].data_markups.currencySymbolFacturar
      }

      return result
    },

    /* priceRentDayExtra() {
      let result = 0
      this.carItems.forEach(carItem => {
        // if (carItem.check) {
        result += carItem.data_markups.tarifaDayExtra

        // }
      })

      return result
    },
    priceRentTarifRetorno() {
      let result = 0
      this.carItems.forEach(carItem => {
        // if (carItem.check) {
        result += carItem.data_markups.precioTarifaRetorno

        // }
      })

      return result
    }, */
  },
  mounted() {
    // PARA Q NAZCA EL MENU IZQ OCULTO
    this.menuIsVerticalNavMini = true
    this.pos = this.posCotizador
    this.getCurrencies()
  },
  created() {
    this.profile()
  },
  methods: {
    ...mapMutations(['setPosCotizador', 'setOnlyShow', 'updateItemToCar', 'emptyClientCarShop', 'updateLoadingBtn']),
    getCurrencies() {
      this.axios
        .get('currency_list?per_page=100000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.currencies = res.data.data
          }
        })
        .finally(() => {
          this.getConfig()
        })
    },
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          }
          this.user = response.data.data
          this.nameCompany = this.user.company.name
          this.nameUser = `${this.user.name} ${this.user.apellidos}`
          this.emailUser = `${this.user.email}`
        })
        .finally(() => {
          this.getItem()
        })
    },
    getConfig() {
      this.axios.get('configuration-view/data-general').then(res => {
        if (res.data.success) {
          this.config = res.data.data.data.data

          if (this.config.emails_noty_reserve) {
            this.config.emails_noty_reserve.forEach(element => {
              if (element.principal) {
                this.emailPrincipal = element.email
              }
            })
          }
          this.nameB2b = this.config.name
          if (this.config.ctas_bancarias) {
            this.ctasBancarias = this.config.ctas_bancarias
          }

          this.$vuetify.theme.themes.light.primary = this.config.color
        }
      })
    },
    getItem() {
      this.axios
        .get(`reservations/${sessionStorage.getItem('cotizations-id')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.item = res.data.data.data
          this.carItems = this.item.car_shop

          // this.updateItemToCar(this.item.car_shop)

          this.item.car_shop.forEach(element => {
            if (element.entity === 'cars') {
              this.priceAllCars += element.data_markups.priceTotal
              this.priceAllReserve += element.data_markups.priceTotal
            }
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    confirmPrint() {
      this.showModalPrint = false
      this.btnName = 'btn-print'
      setTimeout(() => {
        this.isSidebarDataClientActive = true
      }, 50)
    },
    confirmSend() {
      if (this.clientCarShop.name && (this.clientCarShop.email || this.clientCarShop.emails.length > 0)) {
        setTimeout(() => {
          this.isSidebarDataClientActive = false
          this.isSidebarSendCotizationActive = true
        }, 50)
      } else {
        this.btnName = 'btn-send'
        setTimeout(() => {
          this.isSidebarDataClientActive = true
        }, 50)
      }
    },
    showReturnDataClient() {
      setTimeout(() => {
        this.isSidebarDataClientActive = true
        this.isSidebarSendCotizationActive = false
      }, 50)
    },

    sendPdf() {
      if (this.clientCarShop.name && (this.clientCarShop.email || this.clientCarShop.emails.length > 0)) {
        this.showModalSend = false
        setTimeout(() => {
          this.isSidebarDataClientActive = false
          this.isSidebarSendCotizationActive = true
        }, 50)
      } else {
        this.showModalSend = false
        this.btnName = 'btn-send'
        setTimeout(() => {
          this.isSidebarDataClientActive = true
        }, 50)
      }
    },
    confirmSendCotization() {
      const json = {
        subject: this.clientCarShop.subject,
        message: this.clientCarShop.message,
        email: this.clientCarShop.email,
        data_client: this.clientCarShop,
        action: 'send',
        codeReserva: this.item.code,
      }
      this.updateLoadingBtn(true)
      this.axios
        .post('reservations/send-email-adjunt', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === true) {
            this.$toast.success(
              this.$t('msg.sendEmail', {
                email: this.clientCarShop.email ? this.clientCarShop.email : this.clientCarShop.emails,
              }),
            )

            this.isSidebarSendCotizationActive = false
          }
        })
        .finally(() => {
          this.updateLoadingBtn(false)
        })
    },
    downloadPdf() {
      this.loadingDescargar = true
      const json = {
        action: 'download',
        codeReserva: this.item.code,
      }
      this.axios
        .post('reservations/send-email-adjunt', json, { responseType: 'arraybuffer' })
        .then(res => {
          const filename = this.item.code

          this.downloadFile(res, filename)
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          this.loadingDescargar = false
        })
    },
    printPdf() {
      // this.loadingImprimir = true
      window.print()
    },

    downloadFile(response, filename) {
      const newBlob = new Blob([response.data], { type: 'application/pdf' })

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob)

        return
      }

      const data = window.URL.createObjectURL(newBlob)

      const link = document.createElement('a')

      link.href = data
      link.download = `${filename}.pdf`
      link.click()
      setTimeout(() => {
        window.URL.revokeObjectURL(data)
      }, 100)
    },
  },
}
</script>
<style lang="scss">
@import '~@core/preset/preset/apps/invoice.scss';

.printme {
  display: none;
}

@media print {
  .v-application {
    background: none !important;
  }

  .no-printme {
    display: none;
  }
  .printme {
    display: inline;
  }

  .app-navigation-menu,
  .v-app-bar,
  .v-footer,
  .product-buy-now,
  .app-invoice-preview.row .col-12:last-of-type {
    display: none !important;
  }

  .app-invoice-preview.row .col-12:first-child {
    max-width: 100% !important;
    flex: 1 !important;
  }

  .v-main,
  .app-content-container {
    padding: 0px !important;
  }

  .v-card {
    box-shadow: none !important;
  }

  .app-invoice-preview {
    .invoice-header,
    .payment-details,
    .invoice-total {
      &.d-flex {
        flex-direction: row !important;
      }
    }
  }
}
</style>
